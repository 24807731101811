<template>
  <div style="background-color: #FAFAFA;padding-top: 25px">
    <div style="background-color: white">
      <div class="center">
        <div style="height: 120px;display: flex;align-items: center">
          <Select v-model="cgmc" style="width: 150px;margin-right: 10px">
            <Option value="title">成果名称</Option>
            <Option value="colleges">高校名称</Option>
            <Option value="expertscompleting">发明人</Option>
            <Option value="keywords">技术关键词</Option>
          </Select>
          <Input style="width: 350px;margin-right: 30px" v-model="input"/>
          <div style="color:white;border-radius:2px;height: 40px;cursor: pointer;width: 100px;line-height: 40px;text-align: center;background-color: #36c2cf" @click="search()">检索</div>
        </div>
        <div>
          <Table :columns="columns1" :data="data1" @on-row-click="seeDetail" :disabled-hover="true"></Table>
        </div>
        <div style="padding-top: 40px;overflow: hidden;padding-bottom: 200px">
          <Page :total="totalPage" show-elevator style="margin-top: 20px;float: right" @on-change="changePage" />
<!--          <Page :total="100" show-elevator />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getResult,getResultDetail} from "@/plugins/api/resourceApi";
  import {errorToast,warnToast} from "@/plugins/tools/util";

  export default {
    name: "PatentSearch",
    mounted() {
      let keyword = this.$route.query.keyword
      this.params.keywords = keyword;
      this.input = keyword
      this.cgmc = "keywords";
      this.getResultList();
    },
    data(){
      return {
        params:{
          keywords:"",
          title:"",
          colleges:'',
          expertscompleting:'',
          pageNum:1,
          pageSize:10,
        },
        totalPage:1,
        zllx:'',
        flzt:'',
        qlr:'',
        qlflh:'',
        zlflh:'',
        dljg:'',
        dlr:'',
        cgmc:'',
        input:'',
        gxmc:[],
        fmr:[],
        jslb:[],
        columns1: [
          {
            title: "成果图片",
            key: "imageUrl",
            align: 'center',
            width:'100',
            render: (h, params) => {
              return h('img', {
                style: {//设置样式
                  width: '90%',
                  height: '90%',
                  display:'block'
                },
                attrs: {//设置属性
                  src: 'https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png'
                }
              });
            }
          },
          {
            title: '成果名称',
            key: 'title',
            align:'center',
            render: (h, params) => {
              return h('div', [
                h('span', {
                  style: {
                    display: '-webkit-box',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical'
                  },
                  domProps: {
                    title: params.row.title
                  }
                }, params.row.title)
              ])
            }
          },
          {
            title: '归属高校',
            key: 'colleges',
            align:'center'
          },
          {
            title: '成果发明人',
            key: 'expertsCompleting',
            align:'center',
            render: (h, params) => {
              return h('div', [
                h('span', {
                  style: {
                    display: '-webkit-box',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical'
                  },
                  domProps: {
                    title: params.row.expertsCompleting
                  }
                }, params.row.expertsCompleting)
              ])
            }
          },
          {
            title: '技术类别',
            key: 'achieveType',
            align:'center'
          },
        ],
        data1: [
        ]
      }
    },
    methods:{
      seeDetail(row,index){
      let routeUrl = this.$router.resolve({
        path: "/home/PatentSearchDetail",//新页面地址
        query: { id: row.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
      },

      async getResultList() {
        let flag = false;
        for(let item in this.params){
          if(item !== 'pageNum' && item !== 'pageSize'){
            if(this.params[item] != ""){
              flag = true
            }
          }
        }
        if(flag == false){
          warnToast("请至少选择一种进行查询")
        }else {
          let _that = this;
          let json = await getResult(this.params);
          if (json.code == 0) {
            this.data1 = json.result.list;
            this.totalPage = json.result.total;
            // keywords:"",
            //     title:"",
            //     colleges:'',
          } else {
            errorToast(json.message)
          }
        }
      },
      search() {
        this.params.pageNum = 1;
        this.params[this.cgmc] = this.input;
        for(let item in this.params){
          if(this.params[item] != "" && item != this.cgmc){
            this.params[item] = "";
          }
        }

        this.getResultList();
      },
      changePage(index){
        this.params.pageNum = index;
        this.getResultList()
      }
    }
  }
</script>

<style scoped lang="scss">

/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: rgba(55, 197, 207, 0.059) !important;
}

  /deep/ .ivu-table td{
    height: 80px!important;
  }
  /deep/ .ivu-table th{
    height: 60px!important;
  }
  .more {
    display: inline-block;
    float: right;
    width: auto!important;
    cursor: pointer;
  }
  /deep/ .ivu-page-item-active {
    background-color:#36c2cf ;
    color: white!important;
    border: none;
  }
  /deep/ .ivu-page-item-active a {
    color: white!important;
  }
  /deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
    border: #36c2cf;
    background-color:#36c2cf ;
  }
  /deep/ .ivu-checkbox-group {
    display: inline-block;
  }
  .cgfl {
    position: relative;
    height: 50px;
    border-bottom: 1px solid rgba(220, 220, 220, .5);
    padding: 15px 30px;
    & span {
      display: inline-block;
      width: 100px;
    }

  }
  /deep/ .ivu-table-row:hover {
    box-shadow: 15px 15px 15px 0px rgba(127, 127, 127, 0.1)!important;
  }
/deep/ .ivu-table-tbody tr:nth-child(2n):hover {
  box-shadow: 20px 40px 15px 20px rgba(54, 194, 207,.1) inset!important;
}
  /deep/ .ivu-select-selection {
    border-radius: 0;
  }
  .center {
    width: 1200px;
    margin: 0 auto;
  }
</style>
